import * as z from 'zod'

import { FIELDS_OF_LAW } from 'common/constants/fieldsOfLaw'

import { ConfigWeight, ProductType } from './schemaDefinition'

const fieldsOfLaw = Object.keys(FIELDS_OF_LAW)
const isFieldOfLaw = (data: string): boolean => fieldsOfLaw.includes(data)

const fieldOfLawSchema = z.object(
  {
    id: z.string().refine(isFieldOfLaw),
    name: z.string().refine(isFieldOfLaw),
  },
  {
    required_error: 'Bitte geben Sie ein Rechtsgebiet an',
    invalid_type_error: 'Bitte geben Sie ein Rechtsgebiet an',
  }
)

type FieldOfLawFormValue = z.infer<typeof fieldOfLawSchema>

const throttleSchema = z.object({
  apply: z.boolean(),
  max: z.number(),
})

const orderVolumeSchemaBase = z.object({
  weeklyMax: z
    .string()
    .trim()
    .nonempty('Bitte geben Sie ein Maximum an')
    .regex(/^[0-9]*$/, 'Bitte nur Ziffern eintragen'),
})

const localOrderVolumeSchema = orderVolumeSchemaBase.extend({
  fieldOfLaw: z.object(
    {
      id: z.string(),
      name: z.string(),
    },
    {
      required_error: 'Geben Sie ein Rechtsgebiet an.',
      invalid_type_error: 'Geben Sie ein Rechtsgebiet an.',
    }
  ),
})

const emptyOrderVolume = orderVolumeSchemaBase.extend({
  fieldOfLaw: z.null(),
})

export type EmptyOrderVolume = z.infer<typeof emptyOrderVolume>
export type LocalOrderVolume = z.infer<typeof localOrderVolumeSchema>

const simpleUserSchema = z.object({
  id: z.string(),
  name: z.string(),
  email: z.string().email(),
  active: z.boolean(),
})

export type SimpleUser = z.infer<typeof simpleUserSchema>

const testIfExists = (regex: RegExp) => (value: string) => value ? regex.test(value) : true

export const chancellerySchema = z.object({
  deleted: z.boolean(),
  chancelleryId: z.string().optional(),
  eConsultId: z.string().optional(),
  secupay: z.string(),
  services: z.string().optional(),
  taxId: z.string().optional(),
  mollieId: z.string().optional(),
  powerBI1: z.string().optional(),
  powerBI2: z.string().optional(),
  active: z.boolean(),
  callbacks: z.boolean(),
  users: z.array(simpleUserSchema),
  name: z.string().trim().nonempty('Name der Kanzlei ist erforderlich'),
  contactPersonSalutation: z.string(),
  contactPersonFamilyName: z.string().trim().nonempty('Name des Ansprechpartners ist erforderlich'),
  contactPersonGivenName: z.string().trim().nonempty('Vorname des Ansprechpartners ist erforderlich'),
  email: z.string().email('Bitte eine gültige E-Mail-Adresse angeben.'),
  invoiceEmail: z.string().email('Bitte eine gültige E-Mail-Adresse angeben.').or(z.literal('')),
  invoiceNumberRange: z.string().refine(testIfExists(/^.*?[0-9]{3,}$/), 'Bitte nur gültige Rechnungsnummernformate eingeben.'),
  letterheadAddress: z.string().optional(),
  letterheadCity: z.string().optional(),
  letterheadEmail: z.string().optional(),
  letterheadPhone: z.string().optional(),
  letterheadWeb: z.string().optional(),
  letterheadZip: z.string().optional(),
  throttle: throttleSchema,
  phone: z
    .string()
    .trim()
    .nonempty('Telefon ist erforderlich.')
    .regex(/^\+?[0-9 ]*$/, 'Nur Ziffern und Leerzeichen erlaubt.'),
  taxNumber: z.string().optional(),
  iban: z
    .string()
    .refine(
      testIfExists(
        /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/
      ),
      'Bitte nur gültige IBAN eingeben.'
    ),
  orderVolumes: z.array(
    emptyOrderVolume
      .or(localOrderVolumeSchema)
      .refine((data: EmptyOrderVolume | LocalOrderVolume): boolean => !!(data.weeklyMax && data.fieldOfLaw?.name), {
        path: ['fieldOfLaw'],
        message: 'Bitte geben Sie ein Rechtsgebiet an.',
      })
  ),
})

export type ChancelleryFormValues = z.infer<typeof chancellerySchema>

export const chancelleryInitialValues: ChancelleryFormValues = {
  active: true,
  callbacks: false,
  chancelleryId: '', // On edit value from API
  contactPersonFamilyName: '',
  contactPersonGivenName: '',
  contactPersonSalutation: '',
  deleted: false,
  eConsultId: '',
  email: '',
  iban: '',
  letterheadAddress: '',
  letterheadCity: '',
  letterheadEmail: '',
  letterheadPhone: '',
  letterheadWeb: '',
  letterheadZip: '',
  mollieId: '',
  name: '',
  orderVolumes: [],
  phone: '',
  powerBI1: '',
  powerBI2: '',
  secupay: '',
  services: '',
  taxId: '',
  users: [],
  taxNumber: '',
  invoiceEmail: '',
  invoiceNumberRange: '',
  throttle: {
    apply: false,
    max: 0,
  },
}

/**
 * This function takes two strings "which" and "value" and expects them
 * to match HH:mm. It then calculates for each the number of minutes since
 * midnight and checks if "value" is after (= larger than) "which".
 * Note that it does not check if the numbers are really numbers and if they
 * are valid times. We do that before in simpler validations.
 */
const after = (which: string) => (value: string) => {
  if (!which || which.split(':').length < 2 || !value) {
    return true
  }
  const whichArray = which.split(':')
  const valueArray = value.split(':')
  if (valueArray.length < 2) {
    return false
  }
  const timeReducer = (acc: number, curr: string, idx: number): number =>
    idx === 0 ? acc + parseInt(curr, 10) * 60 : acc + parseInt(curr, 10)
  const whichTime = whichArray.reduce(timeReducer, 0)
  const valueTime = valueArray.reduce(timeReducer, 0)
  return valueTime > whichTime
}

const openingTimeSchema = z
  .string({
    required_error: 'Bitte geben Sie eine Uhrzeit an',
  })
  .regex(/^((0\d)|(1\d)|(2[0-3])):[0-5]\d$/, 'Uhrzeiten bitte im Format HH:mm angeben')

const openingHoursSchema = z
  .object({
    enabled: z.boolean(),
    begin: z.string().optional(),
    end: z.string().optional(),
  })
  .superRefine((data, context) => {
    if (!data.enabled) return

    const beginResult = openingTimeSchema.safeParse(data.begin)
    const endResult = openingTimeSchema.safeParse(data.end)

    if (!beginResult.success) {
      beginResult.error.issues.map(issue => context.addIssue({ ...issue, path: ['begin'] }))
    }

    if (!endResult.success) {
      endResult.error.issues.map(issue => context.addIssue({ ...issue, path: ['end'] }))
    }

    if (data.end && data.begin && after(data.end)(data.begin)) {
      context.addIssue({
        message: 'Die Schlusszeit muss nach der Öffnungszeit liegen',
        code: z.ZodIssueCode.custom,
        path: ['end'],
      })
    }
  })

const holidaySchema = z.object({
  global: z.boolean().optional().or(z.null()),
  start: z
    .date({ required_error: 'Urlaubszeiten müssen ein Beginndatum haben' })
    .or(
      z
        .string({ required_error: 'Urlaubszeiten müssen ein Beginndatum haben' })
        .trim()
        .nonempty('Urlaubszeiten müssen ein Beginndatum haben')
    ),
  end: z
    .date({ required_error: 'Urlaubszeiten müssen ein Enddatum haben' })
    .or(
      z
        .string({ required_error: 'Urlaubszeiten müssen ein Enddatum haben' })
        .trim()
        .nonempty('Urlaubszeiten müssen ein Enddatum haben')
    ),
  fieldsOfLaw: z.null().or(z.array(fieldOfLawSchema)),
})

const emptyFieldOfLawPhoneSchema = z.object({
  fieldOfLaw: z.null(),
  phone: z.string().optional().or(z.null()),
})

const localFieldOfLawPhoneSchema = z.object({
  fieldOfLaw: fieldOfLawSchema,
  phone: z
    .string()
    .trim()
    .nonempty('Telefonnummer ist erforderlich')
    .regex(/^\+?[0-9 ]*$/, 'Nur Ziffern und Leerzeichen erlaubt.')
    .optional()
    .or(z.null()),
})

export const chancelleryLocationSchema = z.object({
  active: z.boolean().default(false),
  locationId: z.string().optional(),
  chancelleryId: z.string().optional(),
  deleted: z.boolean(),
  phone: z
    .string()
    .regex(/^\+?[0-9 ]*$/, 'Nur Ziffern und Leerzeichen erlaubt.')
    .optional(),
  streetAddress: z.string().trim().nonempty('Anschrift ist erforderlich'),
  zip: z
    .string()
    .trim()
    .min(1, 'Postleitzahl ist erforderlich')
    .regex(/^\d{5}$/, 'Geben Sie eine gültige Postleitzahl an'),
  city: z.string().trim().nonempty('Stadt ist erforderlich'),
  fallbackFor: z.array(z.string().refine(isFieldOfLaw)),
  openingHours: z.array(openingHoursSchema),
  holidays: z.array(holidaySchema),
  fieldOfLawPhones: z.array(localFieldOfLawPhoneSchema),
  users: z.array(simpleUserSchema),
})

export type EmptyFieldOfLawPhone = z.infer<typeof emptyFieldOfLawPhoneSchema>
export type LocalFieldOfLawPhone = z.infer<typeof localFieldOfLawPhoneSchema>
export type LocationFormValues = Omit<z.infer<typeof chancelleryLocationSchema>, 'fieldOfLawPhones'> & {
  fieldOfLawPhones: Array<EmptyFieldOfLawPhone | LocalFieldOfLawPhone>
}

const emptyOpeningHour = (): LocationFormValues['openingHours'][number] => ({
  enabled: false,
  begin: '',
  end: '',
})

export const locationInitialValues: LocationFormValues = {
  active: false,
  locationId: '',
  chancelleryId: '',
  zip: '',
  city: '',
  phone: '',
  streetAddress: '',
  fallbackFor: [],
  openingHours: Array.from(new Array(7), emptyOpeningHour),
  holidays: [],
  fieldOfLawPhones: [],
  users: [],
  deleted: false,
}

const productSchema = z.object({
  id: z.string(),
  name: z.string(),
  type: z.enum([ProductType.Business, ProductType.Consumer, 'B2B', 'B2C']),
})

const partnerSchema = z.object({ id: z.string(), name: z.string() })

export const configurationSchema = z.object({
  configurationId: z.string(),
  locationId: z.string(),
  active: z.boolean(),
  zipCodes: z.array(
    z
      .string()
      .regex(/^\d+$/, 'Geben Sie eine gültige Postleitzahl an')
      .max(5, 'Stellen Sie sicher, dass die PLZs maximal fünf Ziffern haben')
  ),
  fieldsOfLaw: z.array(z.string().refine(isFieldOfLaw)),
  products: z.array(productSchema),
  partners: z.array(partnerSchema),
  priority: z.number(),
  weight: z.nativeEnum(ConfigWeight),
  fallback: z.boolean(),
  deleted: z.boolean(),
  applyZip: z.boolean(),
})

export type ConfigurationFormValues = z.infer<typeof configurationSchema>
export const configurationInitialValues: ConfigurationFormValues = {
  configurationId: '',
  locationId: '',
  active: true,
  zipCodes: [],
  fieldsOfLaw: [],
  products: [],
  partners: [],
  priority: 0,
  weight: ConfigWeight.Default,
  deleted: false,
  fallback: false,
  applyZip: false,
}

export const configTestSchema = z.object({
  zipCode: z
    .string()
    .trim()
    .min(1, 'Die Postleitzahl ist erforderlich')
    .regex(/^\d{5}$/, 'Geben Sie eine gültige Postleitzahl an'),
  fieldOfLaw: fieldOfLawSchema,
  partner: z.object(
    {
      id: z.string(),
      name: z.string(),
    },
    { errorMap: () => ({ message: 'Bitte wählen Sie einen Partner aus' }) }
  ),
  product: z.object(
    {
      id: z.string(),
      name: z.string(),
    },
    { errorMap: () => ({ message: 'Bitte wählen Sie einen Partner aus' }) }
  ),
  dateTime: z.date(),
})

export type ConfigTestPanelFormValues = Omit<z.infer<typeof configTestSchema>, 'fieldOfLaw'> & {
  fieldOfLaw: null | FieldOfLawFormValue
}

const fiveMinutesInMs = 1000 * 60 * 5
export const configTestPanelInitialValues: ConfigTestPanelFormValues = {
  zipCode: '',
  fieldOfLaw: null,
  partner: { id: 'klugo', name: 'Klugo Erstberatung' },
  product: { id: 'premium-basic', name: 'Beratung Plus' },
  dateTime: new Date(Math.ceil(new Date().getTime() / fiveMinutesInMs) * fiveMinutesInMs),
}
