import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { pathOr } from 'ramda'
import React, { FC, useContext } from 'react'
import { Link, withRouter, RouteComponentProps, useHistory } from 'react-router-dom'
import { Col, Row, Table } from 'reactstrap'

import { SortFilterTh, FilterTagsComponent as FilterTags, Panel, Spinner, Toolbar } from 'common/components'
import { DatePickerInput, TextFilterInput } from 'common/components/FilterInput'
import { ADVICE_REQUESTS_ENDPOINT, FIELDS_OF_LAW_ENTRIES } from 'common/constants'
import { ListProps, withList } from 'common/container/withList'
import { Unpacked } from 'common/interfaces'
import { ButtonClear } from 'common/ui'
import { UserContext } from 'common/user-context'
import { convertToTagValues, formatDateTime, isChannel } from 'common/utils'
import { formatCallbackTime } from 'common/utils/formatter'
import { createGetRowClassName } from 'packages-mui/advice/utils/createGetRowClassName'

import query from '../graphql/fetchAdviceRequests.graphql'
import { AdviceRequestsQuery, FilterOperator, SortDirection } from '../interfaces'

const getOrDefault = pathOr('')
const createOnSort = (func: (id: string) => void, id: string) => () => func(id)

type CallbacksViewProps = ListProps<Unpacked<AdviceRequestsQuery['adviceRequests']['list']>>

const CallbacksList: FC<CallbacksViewProps & RouteComponentProps<{}>> = ({
  list,
  sort,
  page,
  pageSize,
  total,
  filters,
  onSort,
  onFilterRemove,
  onFilterChange,
  onNext,
  onPrev,
  loading,
}) => {
  const history = useHistory()
  const { user } = useContext(UserContext)
  const getRowClassName = createGetRowClassName(user)

  return (
    <>
      <Panel title="Ausstehende Rückrufe">
        <Toolbar pagination={{ page, pageSize, total, label: 'Anfragen' }} onNext={onNext} onPrev={onPrev} />
        <Row>
          <Col sm="12" className="col-padding">
            <Spinner condition={loading} center>
              <FilterTags filters={filters} onTagRemove={onFilterRemove} />
              {list.length > 0 ? (
                <Table hover responsive>
                  <thead>
                    <tr>
                      <SortFilterTh
                        id="preferredCallbackTime"
                        name="preferredCallbackTime"
                        sort={sort}
                        onSort={createOnSort(onSort, 'preferredCallbackTime')}
                        onFilterSubmit={onFilterChange}
                        FilterInput={DatePickerInput}
                        placeholder="Rückruf datum..."
                      >
                        Rückruf Datum
                      </SortFilterTh>
                      <SortFilterTh
                        id="adviceId"
                        name="adviceId"
                        sort={sort}
                        onSort={createOnSort(onSort, 'adviceId')}
                        onFilterSubmit={onFilterChange}
                        FilterInput={TextFilterInput}
                        placeholder="Beratungs ID..."
                      >
                        ID
                      </SortFilterTh>
                      <th>Produkt</th>
                      <SortFilterTh
                        id="fieldOfLaw"
                        name="fieldOfLaw"
                        sort={sort}
                        onSort={createOnSort(onSort, 'fieldOfLaw')}
                        onFilterSubmit={onFilterChange}
                        suggestions={convertToTagValues(FIELDS_OF_LAW_ENTRIES)}
                        FilterInput={TextFilterInput}
                        placeholder="Rechtsgebiet..."
                      >
                        Rechtsgebiet
                      </SortFilterTh>
                      <SortFilterTh
                        id="personName"
                        name="personName"
                        sort={sort}
                        onSort={createOnSort(onSort, 'personName')}
                        onFilterSubmit={onFilterChange}
                        FilterInput={TextFilterInput}
                        placeholder="Name..."
                      >
                        Name
                      </SortFilterTh>
                      <SortFilterTh
                        id="personAddress"
                        name="personAddress"
                        sort={sort}
                        onSort={createOnSort(onSort, 'personAddress')}
                        onFilterSubmit={onFilterChange}
                        FilterInput={TextFilterInput}
                        placeholder="Adresse..."
                      >
                        Adresse
                      </SortFilterTh>
                      <SortFilterTh
                        id="email"
                        name="email"
                        sort={sort}
                        onSort={createOnSort(onSort, 'email')}
                        onFilterSubmit={onFilterChange}
                        FilterInput={TextFilterInput}
                        placeholder="E-Mail..."
                      >
                        E-Mail
                      </SortFilterTh>
                      <SortFilterTh
                        id="phone"
                        name="phone"
                        sort={sort}
                        onSort={createOnSort(onSort, 'phone')}
                        onFilterSubmit={onFilterChange}
                        FilterInput={TextFilterInput}
                        placeholder="Telefon..."
                      >
                        Telefon
                      </SortFilterTh>
                      <SortFilterTh
                        id="createdAt"
                        name="createdAt"
                        sort={sort}
                        onSort={createOnSort(onSort, 'createdAt')}
                        onFilterSubmit={onFilterChange}
                        FilterInput={DatePickerInput}
                        placeholder="Erstellt..."
                      >
                        Erstellt
                      </SortFilterTh>
                      {isChannel(user) && <th className="text-center">zuletzt geöffnet</th>}
                      <th className="text-center">Aktion</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((adviceRequest, index) => (
                      <tr
                        key={index}
                        className={
                          adviceRequest.lastVisited ? getRowClassName({ row: { lastVisited: adviceRequest.lastVisited } }) : ''
                        }
                      >
                        <td className="text-center">
                          {adviceRequest.preferredCallbackTime ? formatCallbackTime(adviceRequest.preferredCallbackTime) : '-'}
                        </td>
                        <th scope="row" className="text-center">
                          <Link to={`/advice-requests/form?adviceId=${adviceRequest.adviceId}`}>{adviceRequest.adviceId}</Link>
                        </th>
                        <td>{adviceRequest.product ? adviceRequest.product.name : 'Basic'}</td>
                        <td>{getOrDefault(['fieldOfLaw', 'name'], adviceRequest)}</td>
                        <td>
                          {getOrDefault(['person', 'firstname'], adviceRequest)}{' '}
                          {getOrDefault(['person', 'lastname'], adviceRequest)}
                        </td>
                        <td>
                          {getOrDefault(['person', 'address', 'street'], adviceRequest)}{' '}
                          {getOrDefault(['person', 'address', 'streetNr'], adviceRequest)}{' '}
                          {getOrDefault(['person', 'address', 'zip'], adviceRequest)}{' '}
                          {getOrDefault(['person', 'address', 'city'], adviceRequest)}
                        </td>
                        <td>{getOrDefault(['person', 'email'], adviceRequest)}</td>
                        <td>{getOrDefault(['person', 'phone'], adviceRequest)}</td>
                        <td className="text-center">{formatDateTime(new Date(adviceRequest.createdAt))}</td>
                        {isChannel(user) && (
                          <td className="text-center">
                            {adviceRequest?.lastVisited?.date ? formatDateTime(new Date(adviceRequest?.lastVisited?.date)) : ''}
                          </td>
                        )}
                        <td className="text-center">
                          <ButtonClear
                            iconLeft={<FontAwesomeIcon icon="pencil-alt" />}
                            onClick={() => {
                              history.push(`/advice-requests/form?adviceId=${adviceRequest.adviceId}`)
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <h3 className="no-data">Es sind keine ausstehenden Rückrufe vorhanden.</h3>
              )}
            </Spinner>
          </Col>
        </Row>
      </Panel>
    </>
  )
}

export const CallbacksView = withList<Unpacked<AdviceRequestsQuery['adviceRequests']['list']>, CallbacksViewProps>({
  query,
  endpoint: ADVICE_REQUESTS_ENDPOINT,
  responseKey: 'adviceRequests',
  queryMapper: listState => ({
    ...listState,
    sort: {
      sortBy: 'preferredCallbackTime',
      sortDirection: SortDirection.Asc,
    },
    filters: [
      ...listState.filters,
      {
        name: 'status',
        operator: FilterOperator.Or,
        value: [
          {
            name: 'status',
            operator: FilterOperator.Equals,
            value: 'awaiting_callback',
          },
          // {
          //   name: 'status',
          //   operator: FilterOperator.Equals,
          //   value: 'paid',
          // },
        ],
      },
    ],
  }),
})(withRouter(CallbacksList))
