import SearchIcon from '@mui/icons-material/Search'
import { Box, ButtonGroup, InputBase, Paper, Typography, Alert } from '@mui/material'
import React, { ReactElement, ReactNode, FunctionComponent, JSXElementConstructor, FC } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { SEARCH } from 'common/constants/routes'
import { useNavigate } from 'common/hooks'

import { PageHeading } from '../PageHeading/PageHeading'

const isReactElement = (element: ReactNode): element is ReactElement => {
  if (
    element &&
    typeof element !== 'boolean' &&
    typeof element !== 'string' &&
    typeof element !== 'number' &&
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    (element as ReactElement).type
  ) {
    return true
  }
  return false
}

const isFunction = (elementType: string | JSXElementConstructor<unknown>): elementType is FunctionComponent => {
  if (typeof elementType === 'function') {
    return true
  }
  return false
}

/**
 * To be used in PageLayout for the buttons inside the page header (download …).
 * You should only provide Button components or the HeaderSearchField as children.
 */
export const HeaderButtonGroup: FunctionComponent = ({ children }) => (
  <ButtonGroup
    variant="contained"
    size="large"
    color="primary"
    sx={theme => ({
      marginBottom: 1,
      backgroundColor: theme.palette.primary.main,
      borderLeft: '1px solid',
      borderRight: '1px solid',
      '&:first-child': {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius,
        borderLeft: 'none',
      },
      '&:last-child': {
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
        borderRight: 'none',
      },
      borderColor: theme.palette.primary.dark,
      display: 'flex',
      alignItems: 'center',
      color: 'white',
    })}
  >
    {children}
  </ButtonGroup>
)

// The inferred displayName which comes from the name of the function is minified in production. So we must set it as property.
// eslint-disable-next-line fp/no-mutation
HeaderButtonGroup.displayName = 'HeaderButtonGroup'

export const HeaderSearchField: FC = () => {
  const doSearch = useNavigate(SEARCH)

  const { control, handleSubmit } = useForm({
    defaultValues: {
      searchTerm: '',
    },
  })
  return (
    <Box component="form" onSubmit={handleSubmit(doSearch)}>
      <Box
        sx={theme => ({
          position: 'relative',
          backgroundColor: 'rgba(255, 255, 255, 0.15)',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.25)',
          },
          borderRadius: theme.shape.borderRadius,
          margin: theme.spacing(0, 1),
        })}
      >
        <Box
          sx={theme => ({
            padding: theme.spacing(0, 1),
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            pointerEvents: 'none',
          })}
        >
          <SearchIcon />
        </Box>
        <Controller
          name="searchTerm"
          control={control}
          render={({ field }) => (
            <InputBase
              {...field}
              aria-label="Suche"
              sx={theme => ({
                color: 'white',
                paddingLeft: theme.spacing(5),
              })}
              name="searchTerm"
              placeholder="Suche mit <Enter>…"
            />
          )}
        />
      </Box>
    </Box>
  )
}

type PageInfolineProps = {
  align?: 'left' | 'center' | 'right'
}

/**
 * Useful for one-line infos, e.g. counts above tables, above the actual page content.
 */
export const PageInfoline: FunctionComponent<PageInfolineProps> = ({ children, align = 'right' }) => (
  <Box display="flex" justifyContent={align === 'right' ? 'flex-end' : align === 'center' ? 'center' : 'flex-start'}>
    <Typography
      variant="subtitle2"
      component="div"
      color="primary"
      gutterBottom
      sx={{ minHeight: theme => `calc(${theme.typography.subtitle2.fontSize} * ${theme.typography.subtitle2.lineHeight})` }}
    >
      {children}
    </Typography>
  </Box>
)

// As above.
// eslint-disable-next-line fp/no-mutation
PageInfoline.displayName = 'PageInfoline'

type PageLayoutProps = {
  heading: string
  headingExtension?: JSX.Element
  breadcrumbs?: JSX.Element
  spacing?: 'table'
  error?: unknown
  errorMessage?: string
}

/**
 * The PageLayout component can be used for table pages as well as form- or other content-based pages.
 * It provides a consistent look for the page's headline, header button group (if any) and a small info line
 * above the actual content.
 * Whereas heading is a non-optional prop, there are special components: @see HeaderButtonGroup and
 * @see PageInfoline for correctly embedding these elements.
 * The content is rendered into a Paper component and for pages which show only a table you should provide
 * the `spacing: 'table'` prop for a more aesthetic look.
 * If a truthy `error` prop is provided, only the headline will be rendered and a generic error message. All
 * other children are ignored.
 */
export const PageLayout: FunctionComponent<PageLayoutProps> = ({
  children,
  heading,
  headingExtension,
  error,
  errorMessage,
  spacing,
  breadcrumbs,
}) => {
  const buttons = React.Children.toArray(children).filter(child =>
    isReactElement(child) && isFunction(child.type) ? child.type.displayName === 'HeaderButtonGroup' : false
  )
  const overline = React.Children.toArray(children).filter(child =>
    isReactElement(child) && isFunction(child.type) ? child.type.displayName === 'PageInfoline' : false
  )
  const content = React.Children.toArray(children).filter(child =>
    isReactElement(child) && isFunction(child.type)
      ? child.type.displayName !== 'HeaderButtonGroup' && child.type.displayName !== 'PageInfoline'
      : true
  )
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PageHeading>{heading}</PageHeading>
          <Box sx={{ ml: 1 }}>{headingExtension}</Box>
        </Box>
        {!error && buttons.length > 0 && buttons[0]}
      </Box>
      {breadcrumbs || null}
      {error ? (
        <Alert severity="error">{errorMessage || 'Ein Fehler ist aufgetreten.'}</Alert>
      ) : (
        <>
          {overline[0] || <PageInfoline />}
          <Paper sx={{ borderRadius: 2, padding: theme => (spacing ? theme.spacing(3, 2) : theme.spacing(3, 3)) }}>
            {content}
          </Paper>
        </>
      )}
    </>
  )
}
