import React, { FC } from 'react'
import { Table } from 'reactstrap'

import { ADVICE_DETAIL } from 'common/constants/routes'
import { useNavigate } from 'common/hooks'
import { formatDate } from 'common/utils'
import { formatCallbackTime } from 'common/utils/formatter'
import { personName, partnerName, address, email, phone, chancelleryName, fieldOfLawName } from 'common/utils/label'
import { callbackAdviceOpened } from 'packages/chancellery-search/actions/callbackAdviceOpened'

import { SearchAdviceRequest } from '../interfaces'

interface Props {
  adviceRequests: Array<SearchAdviceRequest>
}

export const OpenCallbackRequests: FC<Props> = ({ adviceRequests }) => {
  const toAdviceDetail = useNavigate(ADVICE_DETAIL)
  if (adviceRequests.length === 0) {
    return null
  }
  return (
    <>
      <div className="table-heading">
        <h4 className="title">Ausstehende Rückrufe</h4>
      </div>
      <Table hover responsive>
        <thead>
          <tr>
            <th>Rückruf Datum</th>
            <th>ID</th>
            <th>Rechtsgebiet</th>
            <th>Name</th>
            <th>Partner</th>
            <th>Anschrift</th>
            <th>Tel-Nr.</th>
            <th>E-Mail</th>
            <th>Kanzlei</th>
            <th>Erstellt</th>
          </tr>
        </thead>
        <tbody>
          {adviceRequests.map((adviceRequest, index) => (
            <tr
              key={index}
              className="row-hover"
              onClick={() => {
                callbackAdviceOpened({ callbackAdviceOpened: { adviceId: adviceRequest.adviceId, id: adviceRequest.id } })
                toAdviceDetail({ adviceId: adviceRequest.adviceId })
              }}
            >
              <td className="text-center">
                {adviceRequest.preferredCallbackTime ? formatCallbackTime(adviceRequest.preferredCallbackTime) : '-'}
              </td>
              <th scope="row" className="text-center">
                {adviceRequest.adviceId}
              </th>
              <td className="text-center">{fieldOfLawName(adviceRequest)}</td>
              <td className="text-center">{personName(adviceRequest)}</td>
              <td className="text-center">{partnerName(adviceRequest)}</td>
              <td className="text-center">{address(adviceRequest)}</td>
              <td className="text-center">{phone(adviceRequest)}</td>
              <td className="text-center">{email(adviceRequest)}</td>
              <td className="text-center">{chancelleryName(adviceRequest)}</td>
              <td className="text-center">{formatDate(new Date(adviceRequest.createdAt))}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}
