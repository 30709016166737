import classnames from 'classnames'
import React from 'react'
import { FormFeedback, Input, Label, FormGroup } from 'reactstrap'

import './SelectField.scss'

interface Option {
  label: string
  value: string
}

interface SelectFieldProps {
  id: string
  name: string
  label: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.ChangeEventHandler<HTMLInputElement>
  options: Array<Option>
  multiple?: boolean
  error?: string
  highlight?: boolean
  required?: boolean
  disabled?: boolean
  width?: string
  inputWidht?: string
}

type SelectFieldInput = <T extends boolean>(
  props: SelectFieldProps & { value: T extends true ? Array<string> : string }
) => JSX.Element

export const SelectField: SelectFieldInput = ({
  id,
  name,
  label,
  value,
  onChange,
  onBlur,
  multiple,
  options,
  width,
  error,
  inputWidht,
  highlight = false,
  required = false,
  disabled = false,
}) => (
  <FormGroup
    className={classnames('select-field', {
      'select-field--highlight': highlight,
      'select-field--disabled': disabled,
      'select-field--width': !!width,
      'select-field--invalid': error,
    })}
    style={{ flexBasis: width }}
  >
    <Label htmlFor={id}>{required ? `${label} *` : label}</Label>
    <Input
      type="select"
      id={id}
      name={name}
      multiple={multiple}
      value={value}
      disabled={disabled}
      onChange={onChange}
      onBlur={onBlur}
      style={inputWidht ? { width: inputWidht } : {}}
    >
      {options.map(option => (
        <option key={option.value} disabled={option.value === ''} value={option.value}>
          {option.label}
        </option>
      ))}
    </Input>
    {error && <FormFeedback>{error}</FormFeedback>}
  </FormGroup>
)
