export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AnyPrimitive: { input: any; output: any; }
  Date: { input: any; output: any; }
  FilterValue: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

/** @Deprecated */
export type Activity = {
  __typename?: 'Activity';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type AddCallbackAdviceOpenedInput = {
  /** Short Id */
  adviceId?: InputMaybe<Scalars['String']['input']>;
  /** Long Id */
  id?: InputMaybe<Scalars['String']['input']>;
};

/**
 * @todo When updating `saveAdviceRequest
 *       build return value so that only one id
 *       is used
 */
export type AddChancelleryNotReachedInput = {
  /** Short Id */
  adviceId?: InputMaybe<Scalars['String']['input']>;
  chancelleryId: Scalars['String']['input'];
  /** Long Id */
  id?: InputMaybe<Scalars['String']['input']>;
};

/**
 * @todo When updating `saveAdviceRequest
 *       build return value so that only one id
 *       is used
 */
export type AddCustomerNotReachedInput = {
  /** Short Id */
  adviceId?: InputMaybe<Scalars['String']['input']>;
  /** Long Id */
  id?: InputMaybe<Scalars['String']['input']>;
};

export type AddShowMatchingSuggestionInput = {
  /** Short Id */
  adviceId?: InputMaybe<Scalars['String']['input']>;
  chancelleryId: Scalars['String']['input'];
  /** Long Id */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Either 'callback' or 'redirect' */
  via: Scalars['String']['input'];
};

/** Address of chancellery */
export type Address = {
  __typename?: 'Address';
  city: Scalars['String']['output'];
  street: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

/** Main Type of this service. Contains all data about the created advice request. */
export type AdviceRequest = {
  __typename?: 'AdviceRequest';
  actualPrice?: Maybe<Scalars['Float']['output']>;
  /** Generated 4-character adviceId that can be given to clients */
  adviceId: Scalars['String']['output'];
  /** Indicates if client has accepted the KLUGO AGBs. Must be true, otherwise AdviceRequest cannot be created */
  agbsAccepted: Scalars['Boolean']['output'];
  /** Can be used by lawyer to assigned AdviceRequest to other lawyers inside his Chancellery */
  assignedTo?: Maybe<User>;
  /** The billing company information related to this AdviceRequest */
  billingCompany?: Maybe<Company>;
  /** The actual price after discounts including tax that the client paid for this AdviceRequest */
  cancelInformation?: Maybe<CancelInformation>;
  /** Contains any additional semi-structured field data. Mainly used for additional data required by some Partners. */
  caseData?: Maybe<Array<CaseField>>;
  /** The chancellery assigned to this AdviceRequest */
  chancellery?: Maybe<Chancellery>;
  chancelleryNotReached?: Maybe<Array<ChancelleryNotReached>>;
  /** The reason for closing this AdviceRequest */
  closeReason?: Maybe<Scalars['String']['output']>;
  /** The company information related to this AdviceRequest */
  company?: Maybe<Company>;
  /** The way of initiating the contact to the Chancellery. See ContactType for details */
  contactType?: Maybe<ContactType>;
  /** Date of creation of this AdviceRequest */
  createdAt: Scalars['Date']['output'];
  /** User that created this AdviceRequest. Only used when AdviceRequest is created by backend application. Only used when AdviceRequest is created by backend application */
  createdBy?: Maybe<User>;
  customerNotReached?: Maybe<Array<CustomerNotReached>>;
  /** Indicates if client has accepted the KLUGO 'Einwilligungserklärung'. If true KLUGO gets access to more of the clients data */
  dataAccepted?: Maybe<Scalars['Boolean']['output']>;
  /** Clients description of this case */
  description?: Maybe<Scalars['String']['output']>;
  /** Additional disputeAmount field. Used by lawyers */
  disputeAmount?: Maybe<Scalars['Float']['output']>;
  /** Only set if product.id === 'dokumentencheck-basic' otherwise ''  */
  documentCheckLink?: Maybe<Scalars['String']['output']>;
  /** Additional notes that will be visible to the lawyer that receives this AdviceRequest */
  externalNotes?: Maybe<Scalars['String']['output']>;
  /** Whether or not client agreed to feedback */
  feedbackOptIn?: Maybe<Scalars['Boolean']['output']>;
  /** Note that for these id and name are equal. */
  fieldOfLaw?: Maybe<FieldOfLaw>;
  /** Array of files attached by client. (May be modified by lawyer later) */
  files?: Maybe<Array<File>>;
  /** Advicerequest ID of request this request is connected to */
  followsUp?: Maybe<Scalars['String']['output']>;
  /** @Deprecated will be removed and can be found in history */
  forwardedAt?: Maybe<Scalars['Date']['output']>;
  /** If the request was created by a user assigned to a TRB, returns name of TRB otherwise null */
  fromTrb?: Maybe<Scalars['String']['output']>;
  /** Whether or not client supplied any files */
  hasUploads?: Maybe<Scalars['Boolean']['output']>;
  /** Array of events that happend on this AdviceRequest. Mainly used for controlling */
  history?: Maybe<Array<HistoryEvent>>;
  /** Generated unique uuid */
  id: Scalars['String']['output'];
  /** Insurance number of the client if he has one */
  insuranceNumber?: Maybe<Scalars['String']['output']>;
  /** Indicates if client has a legal protection */
  insured: Scalars['Boolean']['output'];
  /** Additional notes that will NOT be visible to lawyer */
  internalNotes?: Maybe<Scalars['String']['output']>;
  /** Generated KLUGO invoice. Only present if product has a price greater 0 */
  invoice?: Maybe<Invoice>;
  lastVisited?: Maybe<LastVisited>;
  /** Additional lawyers notes not related to some event */
  lawyerNotes?: Maybe<Scalars['String']['output']>;
  /** Name of clients insurance company if he has one contracted */
  legalProtectionInsurer?: Maybe<Scalars['String']['output']>;
  /** @Deprecated */
  lockedBy?: Maybe<Scalars['String']['output']>;
  /** Mandate ID. Only used by lawyer when upgrading AdviceRequest to Mandate */
  mandateId?: Maybe<Scalars['String']['output']>;
  /** Mandate Name. Only used by lawyer when upgrading AdviceRequest to Mandate */
  mandateName?: Maybe<Scalars['String']['output']>;
  /** Indicates if client whiches to receive KLUGO newsletter */
  newsletter: Scalars['Boolean']['output'];
  /** The partner associated to this AdviceRequest. Mainly indicates where the client is coming from before arriving at KLUGO. Must be an existing Partner */
  partner?: Maybe<Partner>;
  payment?: Maybe<Payment>;
  paymentMethod?: Maybe<Scalars['String']['output']>;
  /** Whether or not AdviceRequest was already saved once */
  persisted?: Maybe<Scalars['Boolean']['output']>;
  /** The client information related to this AdviceRequest */
  person: Person;
  /** Date and time when client whiches to be called back. Only used when ContactType is CALLBACK */
  preferredCallbackTime?: Maybe<Scalars['Date']['output']>;
  /** KLUGO Product associated to this AdviceRequest */
  product?: Maybe<Product>;
  proposal?: Maybe<Proposal>;
  /** Indicates if AdviceRequest has been opened at least once by lawyer */
  read?: Maybe<Scalars['Boolean']['output']>;
  /** Date at which this AdviceRequest should be picked up again by lawyer. Used by lawyers 'setToPotential' usecase */
  reminderDate?: Maybe<Scalars['Date']['output']>;
  /** Lawyers notes describing the result of first contact to client */
  resultNotes?: Maybe<Scalars['String']['output']>;
  /** Total revenue generated by Mandate. Input by lawyers */
  revenue?: Maybe<Scalars['Float']['output']>;
  /** Indicates if client accepted recovation policy. Must be true, otherwise AdviceRequest cannot be created */
  revocation?: Maybe<Scalars['Boolean']['output']>;
  /** List of all sales (generated by Mandate) and related documents. Input by lawyers */
  sales?: Maybe<Sales>;
  /** Status of the AdviceRequest */
  status: Scalars['String']['output'];
};

export type AdviceRequestInput = {
  adviceType: AdviceType;
  agbsAccepted: Scalars['Boolean']['input'];
  billingCompany?: InputMaybe<CompanyInput>;
  clientNumber?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<CompanyInput>;
  contactType: ContactType;
  dataAccepted: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  fieldOfLawId: Scalars['String']['input'];
  files: Array<FileInput>;
  /** Advicerequest ID of request this request is connected to */
  followsUp?: InputMaybe<Scalars['String']['input']>;
  insuranceNumber?: InputMaybe<Scalars['String']['input']>;
  insured: Scalars['Boolean']['input'];
  legalProtectionInsurer?: InputMaybe<Scalars['String']['input']>;
  newsletter: Scalars['Boolean']['input'];
  partnerId: Scalars['String']['input'];
  partnerReferenceId?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment?: InputMaybe<PaymentInput>;
  person: PersonInput;
  preferredCallbackTime?: InputMaybe<Scalars['Date']['input']>;
  productId: Scalars['String']['input'];
  revocation: Scalars['Boolean']['input'];
};

/** List of AdviceRequests */
export type AdviceRequestList = {
  __typename?: 'AdviceRequestList';
  /** Array ot the actual AdviceRequest Objects */
  list: Array<AdviceRequest>;
  /** Page used in generating array contents */
  page: Scalars['Int']['output'];
  /** Pagesize used in generating array contents */
  pageSize: Scalars['Int']['output'];
  /** Total number of documents available */
  total: Scalars['Int']['output'];
};

export enum AdviceRequestStatus {
  AtChancellery = 'AT_CHANCELLERY',
  AwaitingCall = 'AWAITING_CALL',
  AwaitingCallback = 'AWAITING_CALLBACK',
  Canceled = 'CANCELED',
  Closed = 'CLOSED',
  Complete = 'COMPLETE',
  Editing = 'EDITING',
  Mandate = 'MANDATE',
  Potential = 'POTENTIAL',
  Resubmission = 'RESUBMISSION'
}

export type AdviceRequestsCount = {
  __typename?: 'AdviceRequestsCount';
  intervalCounts: Array<IntervalCount>;
  total: Scalars['Int']['output'];
};

export enum AdviceType {
  Change = 'CHANGE',
  First = 'FIRST',
  NotSet = 'NOT_SET',
  Second = 'SECOND'
}

export type BankAccountInput = {
  bic: Scalars['String']['input'];
  iban: Scalars['String']['input'];
  owner: Scalars['String']['input'];
};

export type CallbackTime = {
  __typename?: 'CallbackTime';
  end: Scalars['Date']['output'];
  start: Scalars['Date']['output'];
};

export type CancelInformation = {
  __typename?: 'CancelInformation';
  description?: Maybe<Scalars['String']['output']>;
  reason: Scalars['String']['output'];
};

/** Parameter object for cancelRequest */
export type CancelRequestInput = {
  /** optional reason for cancelation */
  cancelReason?: InputMaybe<Scalars['String']['input']>;
  /** additional information on cancelreason */
  cancelReasonDescription?: InputMaybe<Scalars['String']['input']>;
  /** adviceRequest uuid */
  id: Scalars['String']['input'];
};

export type CaseField = {
  __typename?: 'CaseField';
  name: Scalars['String']['output'];
  value: Scalars['AnyPrimitive']['output'];
};

export type CaseFieldInput = {
  name: Scalars['String']['input'];
  value: Scalars['AnyPrimitive']['input'];
};

export type Chancellery = {
  __typename?: 'Chancellery';
  activities: Array<Activity>;
  address: Address;
  callbacks?: Maybe<Scalars['Boolean']['output']>;
  callbacksDisabled?: Maybe<Scalars['Boolean']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  languages: Array<Language>;
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  specialities: Array<Speciality>;
};

export type ChancelleryNotReached = {
  __typename?: 'ChancelleryNotReached';
  /** date the customer was called */
  calledAt: Scalars['Date']['output'];
  /** chancellery id */
  chancellery: Scalars['String']['output'];
  /** user id */
  user: Scalars['String']['output'];
};

export type CheckDamageResult = {
  __typename?: 'CheckDamageResult';
  /** ursache */
  cause?: Maybe<Scalars['String']['output']>;
  /** kostenschutzberatung */
  consultation?: Maybe<Scalars['Boolean']['output']>;
  /** schadennummer */
  damageNumber?: Maybe<Scalars['String']['output']>;
  /** selbstbeteiligung */
  deductibleAmount?: Maybe<Scalars['String']['output']>;
  /** streitwert */
  disputeAmount?: Maybe<Scalars['String']['output']>;
  /**
   * There are no garanties that any of the fields is set.
   * Hence, we provide the information if we got a response
   * from the Webservice. Otherwise we´d have to check
   * every key on the FE to be sure.
   */
  exists: Scalars['Boolean']['output'];
  /** kostenschutzaußergerichtlich */
  extrajudicial?: Maybe<Scalars['Boolean']['output']>;
  /** kostenschutz1instanz */
  firstInstance?: Maybe<Scalars['Boolean']['output']>;
  /** Erieignisart */
  incident?: Maybe<Scalars['String']['output']>;
  /** vertragsnummer */
  vnr?: Maybe<Scalars['String']['output']>;
};

/** Response of checkInsuranceNumber */
export type CheckInsuranceResult = {
  __typename?: 'CheckInsuranceResult';
  /** ort */
  city?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Scalars['String']['output']>;
  /** vorname */
  firstname?: Maybe<Scalars['String']['output']>;
  gdvnr?: Maybe<Scalars['String']['output']>;
  /** geschlecht */
  gender?: Maybe<Scalars['String']['output']>;
  /** Computed: True if webservice returns a response, false otherwise */
  insured?: Maybe<Scalars['Boolean']['output']>;
  land?: Maybe<Scalars['String']['output']>;
  /** nachname */
  lastname?: Maybe<Scalars['String']['output']>;
  /** familienstand */
  maritalStatus?: Maybe<Scalars['String']['output']>;
  risks?: Maybe<Array<InsuranceRisks>>;
  /** strasse */
  street?: Maybe<Scalars['String']['output']>;
  /** hausnummer */
  streetNr?: Maybe<Scalars['String']['output']>;
  /** versicherungsnummer */
  vnr?: Maybe<Scalars['String']['output']>;
  /** plz */
  zip?: Maybe<Scalars['String']['output']>;
};

/** Parameter object for setToClosed */
export type CloseInput = {
  /** Reason for closing */
  closeReason: Scalars['String']['input'];
  /** uuid of the AdviceRequest */
  id: Scalars['String']['input'];
  /** Additional lawyers notes. Can be empty string */
  lawyerNotes: Scalars['String']['input'];
  /** Lawyes result notes. Can be empty string */
  resultNotes: Scalars['String']['input'];
};

/** Company that as initiated an AdviceRequest */
export type Company = {
  __typename?: 'Company';
  address?: Maybe<MatchAddress>;
  companyType?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  taxId?: Maybe<Scalars['String']['output']>;
};

/** Input type for an AdviceRequest company */
export type CompanyInput = {
  address?: InputMaybe<MatchAddressInput>;
  companyType?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  taxId?: InputMaybe<Scalars['String']['input']>;
};

export type CompleteInput = {
  id: Scalars['String']['input'];
  noRevenueReason?: InputMaybe<Scalars['String']['input']>;
  revenue?: InputMaybe<Scalars['Float']['input']>;
};

export enum ConfigWeight {
  Default = 'DEFAULT',
  Few = 'FEW',
  Less = 'LESS',
  More = 'MORE',
  Much = 'MUCH'
}

export type ContactChancelleryInput = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export type ContactInput = {
  adviceId: Scalars['String']['input'];
  attachments: Array<Scalars['Upload']['input']>;
  billingCompany?: InputMaybe<CompanyInput>;
  blindCarbonCopy?: InputMaybe<Array<Scalars['String']['input']>>;
  caseData: Array<CaseFieldInput>;
  caseId?: InputMaybe<Scalars['String']['input']>;
  chancellery: ContactChancelleryInput;
  company?: InputMaybe<CompanyInput>;
  description: Scalars['String']['input'];
  fieldOfLaw: Scalars['String']['input'];
  formData?: InputMaybe<Array<FormData>>;
  iban?: InputMaybe<Scalars['String']['input']>;
  insuranceNumber: Scalars['String']['input'];
  message: Scalars['String']['input'];
  messageType: MessageType;
  partyName?: InputMaybe<Scalars['String']['input']>;
  person: PersonInput;
  topic?: InputMaybe<Scalars['String']['input']>;
};

/** Indicates way of contacting matched Chancellery. */
export enum ContactType {
  /** Client will receive a phone call at set date and time and receive his advice */
  Callback = 'CALLBACK',
  /** Client will receive a phone call from the matched Chancellery at some point in a time-window after matching */
  Email = 'EMAIL',
  /** Default value */
  NotSet = 'NOT_SET',
  /** Means a phone call that is redirect to the matched Chancellery immediately */
  Redirect = 'REDIRECT'
}

/** InputField type */
export enum CoverageFieldType {
  Checkbox = 'CHECKBOX',
  Date = 'DATE',
  Email = 'EMAIL',
  Input = 'INPUT',
  InputCurrency = 'INPUT_CURRENCY',
  Number = 'NUMBER',
  Select = 'SELECT',
  TextArea = 'TEXT_AREA'
}

export type CoverageFormConfig = {
  __typename?: 'CoverageFormConfig';
  name: Scalars['String']['output'];
  template: Array<InputField>;
};

/** Response of createAwaitingPayment */
export type CreateAwaitingPaymentResponse = {
  __typename?: 'CreateAwaitingPaymentResponse';
  /** Genrated 4-character adviceId */
  adviceId: Scalars['String']['output'];
  /** Genrated uuid */
  id: Scalars['String']['output'];
};

export type CreatePaymentInput = {
  billingCompany?: InputMaybe<CompanyInput>;
  company?: InputMaybe<CompanyInput>;
  payment: PaymentInput;
  person: PersonInput;
  productId: Scalars['String']['input'];
};

/** Response of createAdviceRequest */
export type CreateResponse = {
  __typename?: 'CreateResponse';
  /** Link to send User to RechtDialog. Only used if product.id === 'dokumentencheck-basic' */
  documentCheckLink?: Maybe<Scalars['String']['output']>;
  /** Genrated 4-character adviceId */
  id: Scalars['String']['output'];
  /** Phone number that should be displayed to client. Only used if ContactType is REDIRECT */
  redirectPhone?: Maybe<Scalars['String']['output']>;
  /** Token used for registration after creating an AdviceRequest. Must be used if the created AdviceRequest should be associated to the new Account. */
  token?: Maybe<Scalars['String']['output']>;
};

export type CustomerNotReached = {
  __typename?: 'CustomerNotReached';
  /** date the customer was called */
  calledAt: Scalars['Date']['output'];
  /** preferred callback slot */
  slot: Scalars['Date']['output'];
  /** user id */
  user: Scalars['String']['output'];
};

export enum ExportType {
  Ascii = 'ascii',
  Controlling = 'controlling',
  Ekomi = 'ekomi'
}

/** Dependency of InputField */
export type FieldDependsOn = {
  __typename?: 'FieldDependsOn';
  field: Scalars['String']['output'];
  value: Scalars['AnyPrimitive']['output'];
};

/** Field of law reference object */
export type FieldOfLaw = {
  __typename?: 'FieldOfLaw';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type File = {
  __typename?: 'File';
  data?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  size?: Maybe<Scalars['Int']['output']>;
};

export type FileInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type Filter = {
  name: Scalars['String']['input'];
  operator: FilterOperator;
  value: Scalars['FilterValue']['input'];
};

export enum FilterOperator {
  After = 'after',
  And = 'and',
  At = 'at',
  Before = 'before',
  /**
   * Will transform `FilterValue` to a regex. Special characters will not be escaped.
   *
   * Example:
   * ```
   * const data = [
   *   "a",
   *   "abc",
   *   "abd",
   *   "ccd"
   * ]
   *
   * const filter = {
   *   value: "a",
   *   operator: "contains"
   * }
   *
   * -> ["a", "abc", "abd"]
   *
   * const filter = {
   *   value: "a",
   *   operator: "equals"
   * }
   *
   * -> ["a"]
   * ```
   *
   * If the Document value by which you want to filter is an array, the `FilterValue` has to match at least one of the values of the beforementioned array.
   */
  Contains = 'contains',
  Empty = 'empty',
  /** Document value must match FilterValue. If Document value is an Array, the Array needs to include the FilterValue */
  Equals = 'equals',
  Gt = 'gt',
  Gte = 'gte',
  Lt = 'lt',
  Lte = 'lte',
  NotEmpty = 'notEmpty',
  NotEquals = 'notEquals',
  Or = 'or',
  ZipAreaContains = 'zipAreaContains',
  ZipAreaEquals = 'zipAreaEquals'
}

export type FinishMatchingInput = {
  /** Indicates if client has accepted the KLUGO AGBs. Must be true, otherwise AdviceRequest cannot be created. When creating AdviceRequests with certain Partner IDs, this can be set to true automatically. */
  agbsAccepted: Scalars['Boolean']['input'];
  /** The billing company information related to this AdviceRequest */
  billingCompany?: InputMaybe<CompanyInput>;
  /** Contains any additional semi-structured field data. Mainly used for additional data required by some Partners. */
  caseData?: InputMaybe<Array<CaseFieldInput>>;
  /** uuid of the Chancellery that should be matched to this AdviceRequest */
  chancelleryId?: InputMaybe<Scalars['String']['input']>;
  /** The company information related to this AdviceRequest */
  company?: InputMaybe<CompanyInput>;
  /** The way of initiating the contact to the Chancellery. See ContactType for details */
  contactType?: InputMaybe<ContactType>;
  /** Indicates if client has accepted the KLUGO 'Einwilligungserklärung'. If true KLUGO gets access to more of the clients data */
  dataAccepted: Scalars['Boolean']['input'];
  /** Clients description of this case */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Additional notes that will be visible to the lawyer that receives this AdviceRequest */
  externalNotes?: InputMaybe<Scalars['String']['input']>;
  /** Whether or not client agreed to feedback */
  feedbackOptIn?: InputMaybe<Scalars['Boolean']['input']>;
  /** Field of law ID used for this AdviceRequest. See AdviceRequest for supported IDs */
  fieldOfLawId: Scalars['String']['input'];
  /** Advicerequest ID of request this request is connected to */
  followsUp?: InputMaybe<Scalars['String']['input']>;
  /** uuid of an existing AdviceRequests if one exists. If undefined is given, a new AdviceRequest will be created */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Insurance number of the client if he has one */
  insuranceNumber?: InputMaybe<Scalars['String']['input']>;
  /** Indicates if client has a legal protection */
  insured?: InputMaybe<Scalars['Boolean']['input']>;
  /** Additional notes that will NOT be visible to lawyer */
  internalNotes?: InputMaybe<Scalars['String']['input']>;
  /** Preferred language of the client */
  language: Scalars['String']['input'];
  /** Name of clients insurance company if he has one contracted */
  legalProtectionInsurer?: InputMaybe<Scalars['String']['input']>;
  /** id of the Partner that should associated to this AdviceRequest */
  partnerId: Scalars['String']['input'];
  /** The client information related to this AdviceRequest */
  person: PersonInput;
  /** Date and time when client whiches to be called back. Only used when ContactType is CALLBACK */
  preferredCallbackTime?: InputMaybe<Scalars['Date']['input']>;
};

export type FormData = {
  label: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type HistoryEvent = {
  __typename?: 'HistoryEvent';
  date: Scalars['Date']['output'];
  payload?: Maybe<Scalars['AnyPrimitive']['output']>;
  type: HistoryEventType;
  user: Scalars['String']['output'];
};

export enum HistoryEventType {
  AdviceConfirmed = 'adviceConfirmed',
  Anonymized = 'anonymized',
  AssignedToChancellery = 'assignedToChancellery',
  AwaitingCallback = 'awaitingCallback',
  CallbackAdviceOpened = 'callbackAdviceOpened',
  Cancelled = 'cancelled',
  ChancelleryNotReached = 'chancelleryNotReached',
  CoverageRequest = 'coverageRequest',
  CustomerNotReached = 'customerNotReached',
  FieldOfLawChanged = 'fieldOfLawChanged',
  InitiallySet = 'initiallySet',
  InsurerInvoice = 'insurerInvoice',
  InsurerMessage = 'insurerMessage',
  MatchingSuggestionOpened = 'matchingSuggestionOpened',
  ProposalAccepted = 'proposalAccepted',
  ProposalInvoiceSent = 'proposalInvoiceSent',
  ProposalRejected = 'proposalRejected',
  RedirectedAdviceRequest = 'redirectedAdviceRequest',
  Rematched = 'rematched',
  SentToControlling = 'sentToControlling',
  SentToEConsult = 'sentToEConsult',
  SetToClosed = 'setToClosed',
  SetToComplete = 'setToComplete',
  SetToMandate = 'setToMandate',
  SetToPotential = 'setToPotential',
  SetToProcessing = 'setToProcessing'
}

/** Input field for a FormTemplate */
export type InputField = {
  __typename?: 'InputField';
  dependsOn?: Maybe<FieldDependsOn>;
  label: Scalars['String']['output'];
  mustUsePath?: Maybe<Scalars['Boolean']['output']>;
  optional?: Maybe<Scalars['Boolean']['output']>;
  /** Only for InputField[type] == CoverageFieldType.Select */
  options?: Maybe<Array<Scalars['String']['output']>>;
  path?: Maybe<Scalars['String']['output']>;
  type: CoverageFieldType;
};

export type InsuranceRisks = {
  __typename?: 'InsuranceRisks';
  arbjahr?: Maybe<Scalars['String']['output']>;
  mvpnachname?: Maybe<Scalars['String']['output']>;
  mvpvorname?: Maybe<Scalars['String']['output']>;
  tarifjahr?: Maybe<Scalars['String']['output']>;
  wagnisart?: Maybe<Scalars['String']['output']>;
  wagniskennzeichen?: Maybe<Scalars['String']['output']>;
  wagnisnummer?: Maybe<Scalars['String']['output']>;
};

/** Parameter object for saveInternalNote */
export type InternalNoteInput = {
  /** 4-character adviceId */
  adviceId: Scalars['String']['input'];
  /** Text field for internal notes */
  internalNotes: Scalars['String']['input'];
};

export enum Interval {
  DayOfMonth = 'dayOfMonth',
  DayOfWeek = 'dayOfWeek',
  Hour = 'hour',
  Month = 'month',
  WeekOfYear = 'weekOfYear'
}

export type IntervalCount = {
  __typename?: 'IntervalCount';
  date: Scalars['Date']['output'];
  value: Scalars['Int']['output'];
};

export type Invoice = {
  __typename?: 'Invoice';
  fileId: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  invoiceNumber: Scalars['String']['output'];
};

export type Klarna = {
  __typename?: 'Klarna';
  clientToken: Scalars['String']['output'];
  paymentMethods?: Maybe<Array<KlarnaPaymentMethod>>;
  sessionId: Scalars['String']['output'];
};

export type KlarnaPaymentMethod = {
  __typename?: 'KlarnaPaymentMethod';
  icon: Scalars['String']['output'];
  identifier: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** Language type */
export type Language = {
  __typename?: 'Language';
  /** ISO-639-1 language code */
  code: Scalars['String']['output'];
};

export type LastMatched = {
  __typename?: 'LastMatched';
  date?: Maybe<Scalars['Date']['output']>;
  notReached?: Maybe<Scalars['Boolean']['output']>;
};

export type LastVisited = {
  __typename?: 'LastVisited';
  /** Timestamp of last visit by Callcenter-Agent */
  date: Scalars['Date']['output'];
  /** UserId of last Callcenter-Agent who opened this request */
  user: Scalars['String']['output'];
};

export type MandateInput = {
  id: Scalars['String']['input'];
  lawyerNotes: Scalars['String']['input'];
  mandateName: Scalars['String']['input'];
  resultNotes: Scalars['String']['input'];
};

/** Address of client. Usable for MatchInput */
export type MatchAddress = {
  __typename?: 'MatchAddress';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  streetNr?: Maybe<Scalars['String']['output']>;
  /** Also known as PostalCode */
  zip?: Maybe<Scalars['String']['output']>;
};

/** Address usable for Matching. At least a zip code is required. Only german addresses are available. If Matching with full address returns 'address_not_found' error, a Matching using only zip code can be tried */
export type MatchAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetNr?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

/** Parameter object of matchChancelleries  */
export type MatchInput = {
  /** Address of MatchInput. Must contain at least a valid PostalCode / Zip */
  address: MatchAddressInput;
  /** ContactType for Matching. If ContactType is REDIRECT matching will include opening times of chancellieries. Use if intention is to redirect client to chancellery per phone after matching */
  contactType?: InputMaybe<ContactType>;
  /** Field of law ID used for Matching. See AdviceRequest for supported IDs */
  fieldOfLaw: Scalars['String']['input'];
  /** Language used for Matching. Only a soft filter. Matching will fall back to default language if needed. */
  language: Scalars['String']['input'];
  /** Partner ID used for Matching. Must be one of partners returned by partners API */
  partnerId?: InputMaybe<Scalars['String']['input']>;
  /** Used to match at the given time when contactType is Callback */
  preferredCallbackTime?: InputMaybe<Scalars['Date']['input']>;
  /** Indicates if premium match should be included. Only used for 'premium-advanded' products */
  premium: Scalars['Boolean']['input'];
  /** Changes matching to only contain chancelleries that have this productId configured */
  productId?: InputMaybe<Scalars['String']['input']>;
  /** Optional timestamp for matching. Used mainly for testing different opening times for chancelleries */
  time?: InputMaybe<Scalars['Date']['input']>;
};

/** Result of a match query */
export type MatchResult = {
  __typename?: 'MatchResult';
  /** All potential matches. Used for controlling */
  candidates: Array<MatchedChancellery>;
  /** Central chancelleries that matched given MatchInput. Should be used fallback if locals are not sufficient */
  centralChancelleries?: Maybe<Array<MatchedChancellery>>;
  /** Central chancellery that matched given MatchInput. Should be used fallback if locals are not sufficient */
  centralChancellery?: Maybe<MatchedChancellery>;
  /** Local chancelleries that matched given MatchInput */
  chancelleries: Array<MatchedChancellery>;
  /** Contains matching chancelleries associated to lawyer. Only used if matching User has reference to Chancelleries */
  ownChancelleries: Array<MatchedChancellery>;
  /** Contains premium chancelleries. Currentyl only used for 'premium-advanced' product */
  premiumChancelleries: Array<MatchedChancellery>;
};

/** Chancellery returned by matching */
export type MatchedChancellery = {
  __typename?: 'MatchedChancellery';
  /** @Deprecated */
  activities: Array<Activity>;
  /** Address of the chancellery location */
  address: Address;
  /** Deprecated. */
  callbacksDisabled?: Maybe<Scalars['Boolean']['output']>;
  /** Uuid of correspoinding chancellery */
  chancelleryId: Scalars['String']['output'];
  /** Uuid of matched config object */
  configId: Scalars['String']['output'];
  /** Current per week count for the location of this match */
  current?: Maybe<Scalars['Int']['output']>;
  /** Calculated distance of Address in MatchInput to Address of this Chancellery */
  distance: Scalars['Float']['output'];
  /** Indicates if this a fallback match */
  fallback?: Maybe<Scalars['Boolean']['output']>;
  /** Fields of law that this chancellery supports */
  fieldsOfLaw: Array<FieldOfLaw>;
  /** Unique uuid of chancellery location */
  id: Scalars['String']['output'];
  /** Languages that this chancellery supports */
  languages: Array<Language>;
  lastMatched?: Maybe<LastMatched>;
  /** Configured maximum for the location of this match if present */
  max?: Maybe<Scalars['Int']['output']>;
  /** Configured minimum for the location of this match if present */
  min?: Maybe<Scalars['Int']['output']>;
  /** Name of the chancellery */
  name: Scalars['String']['output'];
  /** Phone number of chancellery used for redirecting AdviceRequest to chancellery */
  phone: Scalars['String']['output'];
  /** Indicates if this Chancellery can currently accept redirect calls. */
  redirectDisabled?: Maybe<Scalars['Boolean']['output']>;
  services?: Maybe<Scalars['String']['output']>;
  /** @Deprecated */
  specialities: Array<Speciality>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  /** Total count for the location */
  total?: Maybe<Scalars['Int']['output']>;
  /** Configured weight for this match */
  weight?: Maybe<ConfigWeight>;
};

export enum MessageType {
  Contact = 'CONTACT',
  CoverageRequest = 'COVERAGE_REQUEST',
  Invoice = 'INVOICE'
}

export type Mutation = {
  __typename?: 'Mutation';
  addCallbackAdviceOpened: Scalars['String']['output'];
  /** Adds information describing a point in time where a call to a chancellery was unsuccessfull */
  addChancelleryNotReached: Array<ChancelleryNotReached>;
  /** Adds information describing a point in time where a call to a customer was unsuccessfull */
  addCustomerNotReached: Array<CustomerNotReached>;
  addShowMatchingSuggestion: Scalars['String']['output'];
  assignTo: Scalars['String']['output'];
  /** Sets the status of the referenced AdviceRequest to 'cancelled'. Use for misclicked or otherwise falsly create AdviceRequests. This will also take the AdviceRequest away from the chancellery, so they cannot see it anymore. Returns id of AdviceRequest on success */
  cancelRequest: Scalars['String']['output'];
  confirmAdvice: Scalars['Date']['output'];
  contactInsurer: Scalars['String']['output'];
  /** Public API to create an AdviceRequest. Mainly used in klugo.de/erstberatung. See CreateResponse for return type */
  createAdviceRequest: CreateResponse;
  createAwaitingPayment: CreateAwaitingPaymentResponse;
  createNewsletter?: Maybe<Scalars['String']['output']>;
  createOrder: OrderResponse;
  createPayment: PaymentResponse;
  createResubmission: Scalars['String']['output'];
  createSales: Scalars['String']['output'];
  deleteSales: Scalars['String']['output'];
  deleteSalesAttachment: Scalars['String']['output'];
  /** Finishes a matching, assigns the adviceRequest to the chancelleryId given in FinishMatchingInput and sets the status to 'at_chancellery'. After this the adviceRequest will show up in the views of the assigned chancellery. See FinishMatchingInput for more details. Returns id of AdviceRequest on success */
  finishMatching: Scalars['String']['output'];
  lockAdviceRequest: Scalars['String']['output'];
  notifyLdt: Scalars['Date']['output'];
  proposalAccepted: PaymentAccount;
  proposalInvoiceSent: Scalars['String']['output'];
  proposalRejected: Scalars['String']['output'];
  readAdviceRequest: Scalars['String']['output'];
  redirectRequest: Scalars['String']['output'];
  rematch: Scalars['String']['output'];
  /** Saves given partial AdviceRequest. See SaveAdviceRequestInput for details which fields can be saved. Returns id of AdviceRequest on success */
  saveAdviceRequest: Scalars['String']['output'];
  saveAttachments: Scalars['String']['output'];
  /** Saves the given internal note on the AdviceRequest reference in InternalNoteInput. Returns id of AdviceRequest on success */
  saveInternalNote: Scalars['String']['output'];
  saveSalesAttachments: Scalars['String']['output'];
  /** Will send the KLUGO AGBs and 'Einwilligungserklärung' to the given clients E-Mail for review. See SendAGBsInput for details */
  sendAGBs: Scalars['String']['output'];
  sendToEConsult: Scalars['String']['output'];
  setLastVisited?: Maybe<Scalars['Date']['output']>;
  /** Sets the status of the referenced AdviceRequest to 'closed'. Use for standard closing process. Returns id of AdviceRequest on success */
  setToClosed: Scalars['String']['output'];
  setToComplete: Scalars['String']['output'];
  setToMandate: Scalars['String']['output'];
  setToPotential: Scalars['String']['output'];
  setToProcessing: Scalars['String']['output'];
  /** Creates an empty AdviceRequest with a newly generated adviceId, stores in the database and returns if. As name suggests this is used for the TRB usecase */
  startTRB: AdviceRequest;
  unlockAdviceRequest: Scalars['String']['output'];
  updatePayment: Scalars['Boolean']['output'];
  updateSales: Scalars['String']['output'];
};


export type MutationAddCallbackAdviceOpenedArgs = {
  callbackAdviceOpened: AddCallbackAdviceOpenedInput;
};


export type MutationAddChancelleryNotReachedArgs = {
  chancelleryNotReached: AddChancelleryNotReachedInput;
};


export type MutationAddCustomerNotReachedArgs = {
  customerNotReached: AddCustomerNotReachedInput;
};


export type MutationAddShowMatchingSuggestionArgs = {
  showMatchingSuggestion: AddShowMatchingSuggestionInput;
};


export type MutationAssignToArgs = {
  adviceRequestId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationCancelRequestArgs = {
  cancelRequestInput: CancelRequestInput;
};


export type MutationConfirmAdviceArgs = {
  adviceRequestId: Scalars['String']['input'];
};


export type MutationContactInsurerArgs = {
  contactInput: ContactInput;
};


export type MutationCreateAdviceRequestArgs = {
  adviceRequest: AdviceRequestInput;
  overridePerson?: InputMaybe<Scalars['Boolean']['input']>;
  urlPrefix?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateAwaitingPaymentArgs = {
  adviceRequest: AdviceRequestInput;
};


export type MutationCreateNewsletterArgs = {
  email: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
  origin?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateOrderArgs = {
  adviceRequestId: Scalars['String']['input'];
  authToken?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId: Scalars['String']['input'];
};


export type MutationCreatePaymentArgs = {
  payment: CreatePaymentInput;
  urlPrefix: Scalars['String']['input'];
};


export type MutationCreateResubmissionArgs = {
  adviceRequestId: Scalars['String']['input'];
};


export type MutationCreateSalesArgs = {
  adviceRequestId: Scalars['String']['input'];
  revenue: RevenueInput;
};


export type MutationDeleteSalesArgs = {
  adviceRequestId: Scalars['String']['input'];
  revenueId: Scalars['String']['input'];
};


export type MutationDeleteSalesAttachmentArgs = {
  adviceRequestId: Scalars['String']['input'];
  fileId: Scalars['String']['input'];
};


export type MutationFinishMatchingArgs = {
  finishMatching: FinishMatchingInput;
  rank?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationLockAdviceRequestArgs = {
  adviceRequestId: Scalars['String']['input'];
};


export type MutationNotifyLdtArgs = {
  adviceRequestId: Scalars['String']['input'];
};


export type MutationProposalAcceptedArgs = {
  adviceRequestId: Scalars['String']['input'];
  amount: Scalars['Float']['input'];
  urlPrefix: Scalars['String']['input'];
};


export type MutationProposalInvoiceSentArgs = {
  adviceRequestId: Scalars['String']['input'];
};


export type MutationProposalRejectedArgs = {
  adviceRequestId: Scalars['String']['input'];
};


export type MutationReadAdviceRequestArgs = {
  adviceRequestId: Scalars['String']['input'];
  read: Scalars['Boolean']['input'];
};


export type MutationRedirectRequestArgs = {
  adviceRequestId: Scalars['String']['input'];
  chancelleryId: Scalars['String']['input'];
};


export type MutationRematchArgs = {
  rematchInput: RematchInput;
};


export type MutationSaveAdviceRequestArgs = {
  adviceRequest: SaveAdviceRequestInput;
};


export type MutationSaveAttachmentsArgs = {
  adviceRequestId: Scalars['String']['input'];
  files: Array<FileInput>;
};


export type MutationSaveInternalNoteArgs = {
  internalNote: InternalNoteInput;
};


export type MutationSaveSalesAttachmentsArgs = {
  adviceRequestId: Scalars['String']['input'];
  files: Array<FileInput>;
};


export type MutationSendAgBsArgs = {
  sendAGBsInput: SendAgBsInput;
};


export type MutationSendToEConsultArgs = {
  adviceRequestId: Scalars['String']['input'];
};


export type MutationSetLastVisitedArgs = {
  adviceRequestId: Scalars['String']['input'];
};


export type MutationSetToClosedArgs = {
  closeInput: CloseInput;
  unassign?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSetToCompleteArgs = {
  completeInput: CompleteInput;
};


export type MutationSetToMandateArgs = {
  mandateInput: MandateInput;
  sendToEConsult?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSetToPotentialArgs = {
  potentialInput: PotentialInput;
};


export type MutationSetToProcessingArgs = {
  processingInput: ProcessingInput;
};


export type MutationStartTrbArgs = {
  startTRB?: InputMaybe<StartTrb>;
};


export type MutationUnlockAdviceRequestArgs = {
  adviceRequestId: Scalars['String']['input'];
};


export type MutationUpdatePaymentArgs = {
  adviceRequest: AdviceRequestInput;
  paymentId: Scalars['String']['input'];
  paymentMethod: PaymentMethodInput;
};


export type MutationUpdateSalesArgs = {
  adviceRequestId: Scalars['String']['input'];
  revenue: RevenueInput;
  revenueId: Scalars['String']['input'];
};

export type OrderResponse = {
  __typename?: 'OrderResponse';
  checkoutUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  orderNumber: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type Partner = {
  __typename?: 'Partner';
  agbsPreAccepted: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  insured?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
};

export type Payment = {
  __typename?: 'Payment';
  receiverAccount?: Maybe<PaymentAccount>;
};

export type PaymentAccount = {
  __typename?: 'PaymentAccount';
  bankname: Scalars['String']['output'];
  bic: Scalars['String']['output'];
  iban: Scalars['String']['output'];
  owner: Scalars['String']['output'];
  purpose: Scalars['String']['output'];
};

export type PaymentInput = {
  account?: InputMaybe<BankAccountInput>;
  authToken?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  method: PaymentMethodInput;
  payerId?: InputMaybe<Scalars['String']['input']>;
  provider: PaymentProvider;
  transactionId?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentMethodInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export enum PaymentProvider {
  Klarna = 'KLARNA',
  Mollie = 'MOLLIE',
  Paypal = 'PAYPAL',
  Secupay = 'SECUPAY'
}

export type PaymentResponse = {
  __typename?: 'PaymentResponse';
  klarna?: Maybe<Klarna>;
  paypal?: Maybe<Paypal>;
  price: Scalars['Float']['output'];
  secupay?: Maybe<Secupay>;
};

export type PaymentStatusResponse = {
  __typename?: 'PaymentStatusResponse';
  id: Scalars['String']['output'];
  status: Scalars['String']['output'];
  transactionId: Scalars['String']['output'];
};

export type Paypal = {
  __typename?: 'Paypal';
  approvalUrl?: Maybe<Scalars['String']['output']>;
  paymentId: Scalars['String']['output'];
};

/** Client that as initiated an AdviceRequest */
export type Person = {
  __typename?: 'Person';
  address: MatchAddress;
  email?: Maybe<Scalars['String']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  /** Herr / Frau */
  foa?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
};

/** Input type for an AdviceRequest client */
export type PersonInput = {
  /** Matchable address of the client */
  address: MatchAddressInput;
  /** E-Mail address of client. Required because Lawyer needs it to contact Client */
  email: Scalars['String']['input'];
  /** Firstname of the client. Only optional when Product is free */
  firstname?: InputMaybe<Scalars['String']['input']>;
  /** Herr / Frau */
  foa?: InputMaybe<Scalars['String']['input']>;
  /** Lastname of the client. Only optional when Product is free */
  lastname?: InputMaybe<Scalars['String']['input']>;
  /** Phone number of client. Required because Lawyer needs it to contact Client */
  phone: Scalars['String']['input'];
};

export type PotentialInput = {
  id: Scalars['String']['input'];
  lawyerNotes: Scalars['String']['input'];
  reminderDate: Scalars['Date']['input'];
  resultNotes: Scalars['String']['input'];
};

export type ProcessingInput = {
  id: Scalars['String']['input'];
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  netto?: Maybe<Scalars['Float']['output']>;
  originalPrice?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  services?: Maybe<Array<Scalars['String']['output']>>;
  type?: Maybe<ProductType>;
};

export enum ProductType {
  Business = 'BUSINESS',
  Consumer = 'CONSUMER'
}

export type Proposal = {
  __typename?: 'Proposal';
  amount: Scalars['Float']['output'];
  status: ProposalStatus;
};

export enum ProposalStatus {
  Accepted = 'accepted',
  InvoiceSent = 'invoiceSent',
  Rejected = 'rejected'
}

export type Query = {
  __typename?: 'Query';
  /** Returns status of a payment on advice request */
  advicePaymentStatus: PaymentStatusResponse;
  /** Returns a single AdviceRequest */
  adviceRequest: AdviceRequest;
  /** Returns a paginated list of AdviceRequests. Will contain only AdviceRequests that the user has access to */
  adviceRequests: AdviceRequestList;
  /** @Deprecated counting API for outdated controlling pages */
  adviceRequestsCount: AdviceRequestsCount;
  /** Returns all users that your User could assign to an AdviceRequest */
  assignableUsers: Array<User>;
  /** Returns available callbackTime slots starting from current date */
  callbackTimes: Array<CallbackTime>;
  /** Public API to check if a given input would be matchable */
  canMatch: Scalars['Boolean']['output'];
  /** Checks if given DEVK damage number exists and is valid */
  checkDamageNumber: CheckDamageResult;
  /** Checks if given DEVK insurance number exists and is valid */
  checkInsuranceNumber: CheckInsuranceResult;
  /** Returns array of dynamic forms for coverage requests */
  coverageForms: Array<CoverageFormConfig>;
  /** Creates a .csv export of all AdviceRequests matching the given filters and returns a path to the file for download */
  createExport: Scalars['String']['output'];
  /** Creates a .csv export of all sales entries of an AdviceRequest and returns a path to the file for download */
  createSalesDetailsExport: Scalars['String']['output'];
  /** Creates a .csv export of all sales matching the given filters and returns a path to the file for download */
  createSalesExport: Scalars['String']['output'];
  /** Public API to check if a given field of law has premium chancelleries assigned to it */
  hasPremium: Scalars['Boolean']['output'];
  /** Returns an object containing several sets of chancelleries that matched the given input. See MatchResult for details */
  matchChancelleries: MatchResult;
  matchChancelleriesV3: MatchResult;
  /** Returns status of a payment on Mollie order */
  paymentStatus: PaymentStatusResponse;
  /** Performs a full-text search over all AdviceRequests. Will only find AdviceRequests that the user has access to */
  searchAdviceRequest: AdviceRequestList;
};


export type QueryAdvicePaymentStatusArgs = {
  adviceRequestId: Scalars['String']['input'];
};


export type QueryAdviceRequestArgs = {
  adviceId: Scalars['String']['input'];
};


export type QueryAdviceRequestsArgs = {
  filters?: InputMaybe<Array<Filter>>;
  interval?: InputMaybe<Interval>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort>;
  view?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAdviceRequestsCountArgs = {
  filters?: InputMaybe<Array<Filter>>;
  interval?: InputMaybe<Interval>;
};


export type QueryCanMatchArgs = {
  matchInput: MatchInput;
};


export type QueryCheckDamageNumberArgs = {
  damageNumber: Scalars['String']['input'];
};


export type QueryCheckInsuranceNumberArgs = {
  insuranceNumber: Scalars['String']['input'];
};


export type QueryCoverageFormsArgs = {
  fieldOfLaw?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCreateExportArgs = {
  filters?: InputMaybe<Array<Filter>>;
  includeSensitveData?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<Sort>;
  type?: InputMaybe<ExportType>;
  view?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCreateSalesDetailsExportArgs = {
  adviceRequestId: Scalars['String']['input'];
  view?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCreateSalesExportArgs = {
  filters?: InputMaybe<Array<Filter>>;
  sort?: InputMaybe<Sort>;
  view?: InputMaybe<Scalars['String']['input']>;
};


export type QueryHasPremiumArgs = {
  fieldOfLawId: Scalars['String']['input'];
};


export type QueryMatchChancelleriesArgs = {
  matchInput: MatchInput;
};


export type QueryMatchChancelleriesV3Args = {
  matchInput: MatchInput;
};


export type QueryPaymentStatusArgs = {
  adviceRequestId: Scalars['String']['input'];
};


export type QuerySearchAdviceRequestArgs = {
  search: Scalars['String']['input'];
};

export type RematchInput = {
  adviceRequestId: Scalars['String']['input'];
  chancelleryId: Scalars['String']['input'];
  contactType?: InputMaybe<ContactType>;
  fieldOfLawId: Scalars['String']['input'];
  preferredCallbackTime?: InputMaybe<Scalars['Date']['input']>;
};

export type Revenue = {
  __typename?: 'Revenue';
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  revenue: Scalars['Float']['output'];
};

export type RevenueInput = {
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  revenue: Scalars['Float']['input'];
};

export type Sales = {
  __typename?: 'Sales';
  entries: Array<Revenue>;
  files: Array<File>;
};

export type SaveAdviceRequestInput = {
  /** Indicates if client has accepted the KLUGO AGBs. Must be true, otherwise AdviceRequest cannot be created. When creating AdviceRequests with certain Partner IDs, this can be set to true automatically. Defaults to false */
  agbsAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  /** The billing company information related to this AdviceRequest */
  billingCompany?: InputMaybe<CompanyInput>;
  /** Contains any additional semi-structured field data. Mainly used for additional data required by some Partners. */
  caseData?: InputMaybe<Array<CaseFieldInput>>;
  /** The company information related to this AdviceRequest */
  company?: InputMaybe<CompanyInput>;
  /** Indicates if client has accepted the KLUGO 'Einwilligungserklärung'. If true KLUGO gets access to more of the clients data. Defaults to false */
  dataAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Clients description of this case */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Additional notes that will be visible to the lawyer that receives this AdviceRequest */
  externalNotes?: InputMaybe<Scalars['String']['input']>;
  /** Whether or not client agreed to feedback */
  feedbackOptIn?: InputMaybe<Scalars['Boolean']['input']>;
  /** Field of law ID used for this AdviceRequest. See AdviceRequest for supported IDs */
  fieldOfLawId: Scalars['String']['input'];
  /** Advicerequest ID of request this request is connected to */
  followsUp?: InputMaybe<Scalars['String']['input']>;
  /** uuid of the AdviceRequest that should be saved. If undefined a new AdviceRequest is created */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Insurance number of the client if he has one */
  insuranceNumber?: InputMaybe<Scalars['String']['input']>;
  /** Indicates if client has a legal protection */
  insured?: InputMaybe<Scalars['Boolean']['input']>;
  /** Additional notes that will NOT be visible to lawyer */
  internalNotes?: InputMaybe<Scalars['String']['input']>;
  /** Preferred language of the client */
  language: Scalars['String']['input'];
  /** Additional lawyers notes not related to some event */
  lawyerNotes?: InputMaybe<Scalars['String']['input']>;
  /** Name of clients insurance company if he has one contracted */
  legalProtectionInsurer?: InputMaybe<Scalars['String']['input']>;
  /** Mandate Name. Only used by lawyer when upgrading AdviceRequest to Mandate */
  mandateName?: InputMaybe<Scalars['String']['input']>;
  /** Id of the Partner that is associated to this AdviceRequest */
  partnerId?: InputMaybe<Scalars['String']['input']>;
  /** The client information related to this AdviceRequest */
  person: PersonInput;
  /** Starting timestamp of the preferred callback time range */
  preferredCallbackTime?: InputMaybe<Scalars['Date']['input']>;
  /** Lawyers notes describing the result of first contact to client */
  resultNotes?: InputMaybe<Scalars['String']['input']>;
};

export type Secupay = {
  __typename?: 'Secupay';
  iFrameUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  transactionId: Scalars['String']['output'];
};

/** Parameter object for sendAGBs. Contains only information needed to fill an E-Mail text to send to the client */
export type SendAgBsInput = {
  /** 4-character adviceId of the corresponding AdviceRequest */
  adviceId: Scalars['String']['input'];
  /** A clients E-Mail address */
  email: Scalars['String']['input'];
  /** Firstname of the client */
  firstname?: InputMaybe<Scalars['String']['input']>;
  /** Herr / Frau */
  foa?: InputMaybe<Scalars['String']['input']>;
  /** Lastname of the client */
  lastname?: InputMaybe<Scalars['String']['input']>;
};

/** Sorting parameter */
export type Sort = {
  /** Name of a sortable field */
  sortBy: Scalars['String']['input'];
  /** Sorting direction. Either ascending or descending */
  sortDirection: SortDirection;
};

/** Sorting direction */
export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

/** @Deprecated */
export type Speciality = {
  __typename?: 'Speciality';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type StartTrb = {
  partnerId?: InputMaybe<Scalars['String']['input']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  adviceRequestAdded: AdviceRequest;
};


export type SubscriptionAdviceRequestAddedArgs = {
  filters?: InputMaybe<Array<Filter>>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type SearchAdviceRequestQueryVariables = Exact<{
  search: Scalars['String']['input'];
}>;


export type SearchAdviceRequestQuery = { __typename?: 'Query', searchAdviceRequest: { __typename?: 'AdviceRequestList', page: number, pageSize: number, total: number, list: Array<{ __typename?: 'AdviceRequest', id: string, adviceId: string, contactType?: ContactType | null, status: string, createdAt: any, forwardedAt?: any | null, preferredCallbackTime?: any | null, resultNotes?: string | null, reminderDate?: any | null, mandateId?: string | null, mandateName?: string | null, read?: boolean | null, lockedBy?: string | null, revenue?: number | null, fieldOfLaw?: { __typename?: 'FieldOfLaw', id: string, name: string } | null, chancellery?: { __typename?: 'Chancellery', id: string, name: string } | null, partner?: { __typename?: 'Partner', id: string, name: string } | null, product?: { __typename?: 'Product', id: string, name?: string | null } | null, person: { __typename?: 'Person', foa?: string | null, firstname?: string | null, lastname?: string | null, phone: string, email?: string | null, address: { __typename?: 'MatchAddress', zip?: string | null, city?: string | null, street?: string | null, streetNr?: string | null } }, assignedTo?: { __typename?: 'User', id: string, name: string } | null }> } };
